import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import classnames from 'classnames';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIsAuthenticated } from '../../../auth/hooks';
import { useIsAura, useIsModelHub } from '../../../initSettings/hooks';

import { CONFIRMATION_DIALOG__LOGOUT } from '../../../auth/constants/confirmationDialogs';

import { getSiteName } from '../../../initSettings/selectors';

import { actionShowModal } from 'react-redux-app/lib/modules/modal/actions';
import { actionSetIsMenuOpened } from '../../actions/setIsMenuOpened';

import PaidRouletteLink from '../../../auth/components/PaidRouletteLink';
import AuthButtons from '../AuthButtons';
import Banner from '../Banner';
import DropdownMenu from '../DropdownMenu';
import FavoritesMenuLink from '../FavoritesMenuLink';
import LanguageSelector from '../LanguageSelector';
import Logo from '../Logo';
import Menu from '../Menu';
import MessagesMenuLink from '../MessagesMenuLink';
import ReturnToAgencyButton from '../ReturnToAgencyButton';
import UserDropdownMenu from '../UserDropdownMenu';
import VideochatLink from '../VideochatLink';

import { theming } from 'react-redux-app/lib/modules/core/utils';
import { HEADER } from '../../constants/theme';
import baseCssJson from './styles/base/index.css.json';


const themeApi = [
  'container',
  'bar',
  'logo',
  'menu',
  'menu__item',
  'videochat',
  'logout',
  'modelMenuToggle',
  'button',
  'hiddenToXL',
  'hiddenFromXL',
  'hiddenToXXXL',
];

const propTypes = {
  theme: theming.getThemePropTypesShape(themeApi).isRequired,
};

const Header = ({ theme }) => {
  const dispatch = useDispatch();

  const siteName = useShortSelector(getSiteName);
  const isAuthenticated = useIsAuthenticated();
  const isAura = useIsAura();
  const isModelHub = useIsModelHub();

  const menu = useMemo(() => {
    if (isAura) {
      return (
        <>
          <VideochatLink
            className={classnames(theme.menu__item, theme.videochat)}
          />

          <div
            onClick={() => {
              dispatch(actionShowModal(CONFIRMATION_DIALOG__LOGOUT, { siteName }));
            }}
            className={theme.logout}
          />

          <div
            onClick={() => { dispatch(actionSetIsMenuOpened()); }}
            className={classnames('material-icons-outlined', theme.modelMenuToggle)}
          />
        </>
      );
    }
    if (isModelHub) {
      return (
        <>
          <ReturnToAgencyButton className={classnames(theme.menu__item, theme.button)} />

          <VideochatLink className={classnames(theme.menu__item, theme.videochat)} />

          <LanguageSelector className={theme.menu__item} />

          {isAuthenticated ? <UserDropdownMenu /> : <AuthButtons className={theme.button} />}
        </>
      );
    }
    return (
      <>
        <Banner className={classnames(theme.menu__item, theme.hiddenToXXXL)} />

        <PaidRouletteLink className={theme.menu__item} />

        <VideochatLink
          className={classnames(theme.menu__item, theme.videochat, theme.hiddenFromXL)}
        />

        <FavoritesMenuLink className={classnames(theme.menu__item, theme.hiddenFromXL)} />
        <MessagesMenuLink className={classnames(theme.menu__item, theme.hiddenFromXL)} />

        <LanguageSelector className={classnames(theme.menu__item, theme.hiddenFromXL)} />

        <DropdownMenu className={theme.hiddenFromXL} />

        <Menu className={theme.hiddenToXL} />
      </>
    );
  }, [isAuthenticated]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container fluid className={theme.container}>
      <Row className={theme.bar}>
        <Col className={theme.logo}>
          <Logo />
        </Col>

        <Col className={theme.menu}>
          {menu}
        </Col>
      </Row>
    </Container>
  );
};

Header.propTypes = propTypes;

export default theming.theme(
  HEADER,
  baseCssJson,
  themeApi
)(Header);
