import { getUri } from 'react-redux-app/lib/modules/routing/utils';
import { getAuraIndexUrl } from '../../../app/utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAura } from '../../selectors';


export default ({ getState, dispatch }) => (
  function checkAuraHandler(nextState, successCallback, errorCallback) {
    const indexUrl = getAuraIndexUrl(getCurrentLangForUrl(getState()));

    if (getIsAura(getState()) && !getUri(nextState.location).startsWith(indexUrl)) {
      errorCallback(indexUrl);
      return;
    }

    successCallback();
  }
);
