import { dom } from 'react-redux-app/lib/modules/core/utils';
import preloadImage from '../../core/utils/preloadImage';
import { getFilter } from '../../core/loadableItems/utils';

import {
  ACTION_ADD_UNDERWEAR,
  ACTION_SET_UNDERWEAR,

  ACTION_SET_UNDERWEAR_PRESERVE,

  ACTION_LOAD_UNDERWEAR__REQUEST,
  ACTION_LOAD_UNDERWEAR__SUCCESS,
  ACTION_LOAD_UNDERWEAR__FAIL,
} from '../actionTypes/underwear';

import { PAGINATION_MODE__SCROLL } from '../../core/loadableItems/constants';
import { ORDER__CHEAP, ORDER__EXPENSIVE } from '../../core/switch/constants';

import { ITEMS_SCHEMA } from '../../core/schemas/items';

import { getUnderwearPaginationMode } from '../selectors/underwear';

import { actionAddException } from 'react-redux-app/lib/modules/exception/actions';
import { actionAddSaleItems } from './index';


const actionAddUnderwear = underwear => ({
  type: ACTION_ADD_UNDERWEAR,
  payload: { underwear },
});

export const actionSetUnderwear = underwear => ({
  type: ACTION_SET_UNDERWEAR,
  payload: { underwear },
});

export const actionSetUnderwearPreserve = () => ({
  type: ACTION_SET_UNDERWEAR_PRESERVE,
});

const actionLoadUnderwearSuccess = () => ({
  type: ACTION_LOAD_UNDERWEAR__SUCCESS,
});

const actionLoadUnderwearFail = error => ({
  type: ACTION_LOAD_UNDERWEAR__FAIL,
  payload: { error },
});

export const actionLoadUnderwearRequest = () => (
  (dispatch, getState, { apiClientHelper, history: { location } }) => {
    const preserve = getUnderwearPaginationMode(getState()) === PAGINATION_MODE__SCROLL;

    dispatch({
      type: ACTION_LOAD_UNDERWEAR__REQUEST,
    });

    const {
      nick,
      page = 1,
      orderBy,
      limit = 60,
    } = getFilter(location);

    const params = { limit };
    if (nick) {
      params.nick = nick;
    }
    if (orderBy && orderBy === ORDER__CHEAP) {
      params.sortField = 'cost';
      params.sortDirection = 'asc';
    }
    if (orderBy && orderBy === ORDER__EXPENSIVE) {
      params.sortField = 'cost';
      params.sortDirection = 'desc';
    }

    return apiClientHelper.get(
      `underwear/list/${page}`,
      { params, retry: true },
      ITEMS_SCHEMA
    ).then(
      ({ result: underwear, entities }) => {
        const items = entities.items || {};

        dispatch(actionAddSaleItems(items));

        if (!dom.isBrowser()) {
          dispatch(actionSetUnderwear(underwear));

          return dispatch(actionLoadUnderwearSuccess());
        }

        // Preload underwear thumbs
        const promises = Object.keys(items).map(id => preloadImage(items[id].thumbUrl));

        return Promise.all(promises).then(() => {
          if (preserve) {
            dispatch(actionAddUnderwear(underwear));
          } else {
            dispatch(actionSetUnderwear(underwear));
          }

          dispatch(actionLoadUnderwearSuccess());
        });
      },
      error => {
        dispatch(actionLoadUnderwearFail(error));
      }
    ).catch(error => {
      dispatch(actionAddException(error));
    });
  }
);
