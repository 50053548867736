import { saleTypeLangKeys } from '../../balance/constants';


const STATISTICS_TYPE__PRIVATE = 'private';
const STATISTICS_TYPE__PUBLIC = 'public';

export const statisticsTypeLangKeys = {
  ...saleTypeLangKeys,
  [STATISTICS_TYPE__PRIVATE]: 'msg.statistics-type.private',
  [STATISTICS_TYPE__PUBLIC]: 'msg.statistics-type.public',
};

export const PAYING_MEMBERS_GRID_NAME = 'PayingMembersGrid';
